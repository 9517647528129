import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ConstructionIcon from '@mui/icons-material/Construction';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import { Link } from 'react-router-dom';
import avalanche from '../Assets/Images/dropdown/avalanche.svg'
import arbitrum from '../Assets/Images/dropdown/arbitrum.svg'
import aurora from '../Assets/Images/dropdown/aurora.svg'
import bnb from '../Assets/Images/dropdown/bnb.svg'
import fantom from '../Assets/Images/dropdown/fantom.svg'
import gnosis from '../Assets/Images/dropdown/gnosis.svg'
import optimism from '../Assets/Images/dropdown/optimism.svg'
import klaytn from '../Assets/Images/dropdown/klaytn.svg'
import polygon from '../Assets/Images/dropdown/polygon.svg'
import zksync from '../Assets/Images/dropdown/zksync.svg'
import ethereum from '../Assets/Images/dropdown/ethereum.svg'
import eth from '../Assets/Images/eth.png'
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsIcon from '@mui/icons-material/Settings';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

function ResponsiveNav() {

    const [trade, setTrade] = useState(false)
    const [earn, setEarn] = useState(false)
    const [bridge, setBridge] = useState(false)





    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className='ResposiveNavMain'>
            <div className='LogoHolderResponsive'>
                <a href="http://deta.io">
                <img src={require("../Assets/Images/logo.png")} alt="" />
                </a>
            </div>
            <div>
                <div className="d-flex">
                    <div class="dropdown">


                        <div className='HoverTabsHolder'>
                            <a className='nav-titles padding-zero'>
                                <button class="gradient-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img className='Nav-Diamond' src={eth} />

                                    <span className='hideNavText'>Ethereum</span>

                                </button>
                            </a>
                            <div className='Drop-down-container'>
                                <a className='a-unset-all' >
                                    <div className='Drop-down-items'>
                                        <div className='Drop-down-items-icons'>
                                            <div className='Icons-image-for-drop-down'>
                                                <img class src={ethereum} alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <p >Ethereum</p>
                                        </div>
                                    </div>
                                </a>

                                <a className='a-unset-all' >
                                    <div className='Drop-down-items'>
                                        <div className='Drop-down-items-icons'>
                                            <div className='Icons-image-for-drop-down'>
                                                <img className src={bnb} alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <p >BNB Chain bridge</p>
                                        </div>
                                    </div>
                                </a>
                                <a className='a-unset-all' >
                                    <div className='Drop-down-items'>
                                        <div className='Drop-down-items-icons'>
                                            <div className='Icons-image-for-drop-down'>
                                                <img class src={polygon} alt="" />
                                            </div>                                            </div>
                                        <div>
                                            <p >Polygon bridge</p>
                                        </div>
                                    </div>
                                </a>
                                <a className='a-unset-all' >
                                    <div className='Drop-down-items'>
                                        <div className='Drop-down-items-icons'>
                                            <div className='Icons-image-for-drop-down'>
                                                <img class src={optimism} alt="" />
                                            </div>                                            </div>
                                        <div>
                                            <p >Optimism bridge</p>
                                        </div>
                                    </div>
                                </a>
                                <a className='a-unset-all' >
                                    <div className='Drop-down-items'>
                                        <div className='Drop-down-items-icons'>
                                            <div className='Icons-image-for-drop-down'>
                                                <img class src={arbitrum} alt="" />
                                            </div>                                            </div>
                                        <div>
                                            <p >Arbitrum bridge</p>
                                        </div>
                                    </div>
                                </a>

                            </div>
                        </div>

                    </div>
                    <div className='Navbar-btn-2 hideNavItems'>
                        <img className='Nav-wallet' src={require("../Assets/Images/wallet.png")} alt="" />

                        Connect Wallet
                    </div>
                    <div className='d-flex align-items-center mx-2 hideNavItems'>
                        <SettingsIcon style={{ color: "white" }} />
                    </div>
                    <div onClick={handleShow} className='d-flex align-items-center mx-2'>
                        <MenuIcon style={{ color: "white" }} />
                    </div>
                </div>
            </div>
            <Offcanvas className="offcanvasNavbar offcanvas" show={show} onHide={handleClose} placement='end'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <div className='LogoHolderResponsive'>
                        <a href="http://deta.io">
                            <img src={require("../Assets/Images/logo.png")} alt="" />
                            </a>
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0 myoffcanvas'>
                    <div className='responsiveNavItemsholder'>
                        <div onClick={() => setTrade(!trade)} className={`responsiveNavItems ${trade ? 'hoverOnresponsive' : ''}`}>
                            <div className='w-100 inner-container-for-dropdown'>

                                <div>
                                    Trade
                                </div>
                                <div>
                                    <KeyboardArrowDownIcon />
                                </div>
                            </div>
                            <div className='DropDownCollapseResponsiveNav'>

                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <Link to='/' className='a-unset-all'>
                                        <div className='grey-txt margint-2' >
                                            <SpaceDashboardIcon style={{ color: '#6985ab' }} className='mx-2' />

                                            Simple mode
                                        </div>
                                    </Link>
                                </div>
                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <Link to='/classic-mode' className='a-unset-all'>
                                        <div className='grey-txt margint-2' >
                                            <DashboardCustomizeIcon style={{ color: '#6985ab' }} className='mx-2' />

                                            Classic mode
                                        </div>
                                    </Link>
                                </div>


                            </div>
                        </div>
                        <div onClick={() => setEarn(!earn)} className={`responsiveNavItems ${earn ? 'hoverOnresponsive' : ''}`}>
                            <div className='w-100 inner-container-for-dropdown'>

                                <div>
                                    Earn
                                </div>
                                <div>
                                    <KeyboardArrowDownIcon />
                                </div>
                            </div>
                            <div className='DropDownCollapseResponsiveNav'>

                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <Link to='/' className='a-unset-all'>
                                        <div className='grey-txt margint-2' >
                                            <AccountCircleIcon style={{ color: '#6985ab' }} className='mx-2' />

                                            Delegate
                                        </div>
                                    </Link>
                                </div>
                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <Link to='/' className='a-unset-all'>
                                        <div className='grey-txt margint-2' >
                                            <ConstructionIcon style={{ color: '#6985ab' }} className='mx-2' />

                                            Strategies
                                        </div>
                                    </Link>
                                </div>
                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <Link to='/pool' className='a-unset-all'>
                                        <div className='grey-txt margint-2' >
                                            <DonutSmallIcon style={{ color: '#6985ab' }} className='mx-2' />

                                            Pools
                                        </div>
                                    </Link>
                                </div>


                            </div>
                        </div>
                        <div onClick={() => setBridge(!bridge)} className={`responsiveNavItems ${bridge ? 'hoverOnresponsive' : ''}`}>
                            <div className='w-100 inner-container-for-dropdown'>

                                <div>
                                    Bridges
                                </div>
                                <div>
                                    <KeyboardArrowDownIcon />
                                </div>
                            </div>
                            <div className='DropDownCollapseResponsiveNav'>

                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <a target='_blank' href='https://cbridge.celer.network/' className='a-unset-all'>
                                        <div className='grey-txt d-flex margint-2' >
                                            <div className='Icons-image-for-drop-down mx-2'>

                                                <img className='' src={bnb} alt="" />
                                            </div>
                                            <div>
                                                BNB Chain bridge
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <a target='_blank' href='https://wallet.polygon.technology/polygon/bridge/' className='a-unset-all'>
                                        <div className='grey-txt d-flex margint-2' >
                                            <div className='Icons-image-for-drop-down mx-2'>

                                                <img className='' src={polygon} alt="" />
                                            </div>
                                            <div>
                                                Polygon bridge
                                            </div>
                                        </div>
                                    </a>
                                </div>


                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <a target='_blank' href='https://app.optimism.io/bridge' className='a-unset-all'>
                                        <div className='grey-txt d-flex margint-2' >
                                            <div className='Icons-image-for-drop-down mx-2'>

                                                <img className='' src={optimism} alt="" />
                                            </div>
                                            <div>
                                                Optimism bridge
                                            </div>
                                        </div>
                                    </a>
                                </div>


                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <a target='_blank' href='https://bridge.arbitrum.io/' className='a-unset-all'>
                                        <div className='grey-txt d-flex margint-2' >
                                            <div className='Icons-image-for-drop-down mx-2'>

                                                <img className='' src={arbitrum} alt="" />
                                            </div>
                                            <div>
                                                Arbitrum bridge
                                            </div>
                                        </div>
                                    </a>
                                </div>



                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <a target='_blank' href='https://omni.gnosischain.com/bridge' className='a-unset-all'>
                                        <div className='grey-txt d-flex margint-2' >
                                            <div className='Icons-image-for-drop-down mx-2'>

                                                <img className='' src={gnosis} alt="" />
                                            </div>
                                            <div>
                                                Gnosis Chain bridge
                                            </div>
                                        </div>
                                    </a>
                                </div>




                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <a target='_blank' href='https://core.app/bridge/' className='a-unset-all'>
                                        <div className='grey-txt d-flex margint-2' >
                                            <div className='Icons-image-for-drop-down mx-2'>

                                                <img className='' src={avalanche} alt="" />
                                            </div>
                                            <div>
                                                Avalanche bridge
                                            </div>
                                        </div>
                                    </a>
                                </div>



                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <a target='_blank' href='https://app.multichain.org/#/router' className='a-unset-all'>
                                        <div className='grey-txt d-flex margint-2' >
                                            <div className='Icons-image-for-drop-down mx-2'>

                                                <img className='' src={fantom} alt="" />
                                            </div>
                                            <div>
                                                Fantom bridge
                                            </div>
                                        </div>
                                    </a>
                                </div>



                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <a target='_blank' href='https://scope.klaytn.com/bridge' className='a-unset-all'>
                                        <div className='grey-txt d-flex margint-2' >
                                            <div className='Icons-image-for-drop-down mx-2'>

                                                <img className='' src={klaytn} alt="" />
                                            </div>
                                            <div>
                                                Klaytn bridge
                                            </div>
                                        </div>
                                    </a>
                                </div>


                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <a target='_blank' href='https://rainbowbridge.app/transfer' className='a-unset-all'>
                                        <div className='grey-txt d-flex margint-2' >
                                            <div className='Icons-image-for-drop-down mx-2'>

                                                <img className='' src={aurora} alt="" />
                                            </div>
                                            <div>
                                                Aurora bridge
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div onClick={() => setShow(!show)} className='margint-2'>
                                    <a target='_blank' href='https://bridge.zksync.io/' className='a-unset-all'>
                                        <div className='grey-txt d-flex margint-2' >
                                            <div className='Icons-image-for-drop-down mx-2'>

                                                <img className='' src={zksync} alt="" />
                                            </div>
                                            <div>
                                                ZKSync Era bridge
                                            </div>
                                        </div>
                                    </a>
                                </div>



                            </div>
                        </div>

                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default ResponsiveNav