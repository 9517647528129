import icon1 from '../Assets/Images/pool/icon1.svg'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Poolitem({ item }) {
    return (
        <div className='Pool-row'>
            <div className="Pool-row-item-1">
                <div className='d-flex'>
                    <div className='d-flex align-items-center mx-1'>
                        <div className='Pool-row-icon1'>
                            <img className='w-100' src={icon1} alt="" />
                        </div>
                        <div className='font-12'>
                            {item.pair.slice(0, 6) + '...'}
                        </div>
                    </div>
                    <div className='mx-1 d-flex'>
                        <div className='Pool-row-icon1'>
                            <img className='w-100' src={require('../Assets/Images/pool/icon2.webp')} alt="" />
                        </div>
                        <div>
                            <div className='font-12'>
                                {item.token0.symbol.slice(0, 4)}

                            </div>
                            <div className='font-12 primary-color'>
                                50%
                            </div>
                        </div>
                    </div>
                    <div className='mx-1 d-flex'>
                        <div className='Pool-row-icon1 '>
                            <img className='w-100' src={require('../Assets/Images/pool/icon3.webp')} alt="" />
                        </div>
                        <div>
                            <div className='font-12'>
                                {item.token1.symbol.slice(0, 4)}
                            </div>
                            <div className='font-12 primary-color'>
                                50%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Pool-row-item-2">
                <div className='font-14 margin-inline-3'>
                    ${Math.floor(item.reserveUSD)}
                </div>
                <div className='font-14 margin-inline-3'>
                    {item.apy.toString().slice(0, 4)}%
                    <InfoOutlinedIcon style={{ fontSize: '16px', marginLeft: '4px' }} />

                </div>
            </div>
            <div className="Pool-row-item-3">
                <div className='Tune-Icon-Pool'>

                    <TuneIcon />
                </div>
                <div>

                    <NavigateNextIcon />
                </div>
            </div>

        </div>
    )
}

export default Poolitem