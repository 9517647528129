import TuneIcon from '@mui/icons-material/Tune';
import icon1 from '../Assets/Images/pool/icon1.svg'

import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
function PoolitemResponsive({ item }) {
    return (
        <div className='pool-item-responsive-card'>
            <div className='pool-item-responsive-card-inner-r1'>
                <div>
                    <div>
                        <div className='d-flex align-items-center mx-1'>
                            <div className='Pool-row-icon1'>
                                <img className='w-100' src={icon1} alt="" />
                            </div>
                            <div className='font-12'>
                                {item.pair.slice(0, 6) + '...'}
                            </div>
                        </div>
                        <div className='d-flex mt-3'>
                            <div className='mx-1 d-flex'>
                                <div className='Pool-row-icon1'>
                                    <img className='w-100' src={require('../Assets/Images/pool/icon2.webp')} alt="" />
                                </div>
                                <div>
                                    <div className='font-12'>
                                        {item.token0.symbol.slice(0, 4)}

                                    </div>
                                    <div className='font-12 primary-color'>
                                        50%
                                    </div>
                                </div>
                            </div>
                            <div className='mx-1 d-flex'>
                                <div className='Pool-row-icon1 '>
                                    <img className='w-100' src={require('../Assets/Images/pool/icon3.webp')} alt="" />
                                </div>
                                <div>
                                    <div className='font-12'>
                                        {item.token1.symbol.slice(0, 4)}
                                    </div>
                                    <div className='font-12 primary-color'>
                                        50%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='TunerIconResponsive'>
                    <TuneIcon style={{ color: "#6c86ad" }} />
                </div>
            </div>
            <div className='pool-item-responsive-card-inner-r2'>
                <div className='inner-r2-inner'>
                    <div className='font-12 grey-txt'>
                        Liquidity
                    </div>
                    <div className='font-12'>
                        ${Math.floor(item.reserveUSD)}
                    </div>
                </div>
                <div className='inner-r2-inner'>
                    <div className='font-12 grey-txt'>
                        Total APY
                    </div>
                    <div className='font-12'>
                        {item.apy.toString().slice(0, 4)}%
                    </div>
                </div>
                <div className='inner-r2-inner'>
                    <div className='font-12 grey-txt margin-left-1'>
                        <HorizontalRuleIcon style={{ color: "#6c86ad" }} /> Pool APY
                    </div>
                    <div className='font-12'>
                        {item.apy.toString().slice(0, 4)}%
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PoolitemResponsive