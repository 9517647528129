import { useState } from 'react'
import Header from '../Components/Header'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PublicOffOutlinedIcon from '@mui/icons-material/PublicOffOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ExchangeCard from '../Components/ExchangeCard';
import SwapSetting from '../Components/SwapSetting';
function LandingPage() {
    return (
        <div>

            <div className='first-section'>
                <div className='first-section-inner'>
                    {/* <div className='first-section-card'>
                        <div className='Main-page-card-sec-1'>
                            <div className='d-flex align-items-center'>
                                <div className='text-1 '>Swap</div>

                            </div>
                            <div className='card-icons-holder-main'>
                                <div className='Card-icon-div'>
                                    <img className='w-100' src={require("../Assets/Images/refresh.png")} alt="" />
                                </div>
                                <div className='Card-icon-div'>
                                    <img className='w-100' src={require("../Assets/Images/plus.png")} alt="" />
                                </div>
                                <div className='Card-icon-div'>
                                    <img className='w-100' src={require("../Assets/Images/filter.png")} alt="" />
                                </div>

                            </div>
                        </div>
                        <div className='Main-page-card-sec-2' >
                            <div>
                                <div className='Text-small'>
                                    You sell
                                </div>
                                <div className='font-size-20 d-flex hover-effect icon-text-container'>

                                    <img className='icon-1-inch' src={require("../Assets/Images/coins/ethereum.webp")} alt="" />

                                    WETH
                                    <div className='Container-of-arrow'>

                                        <img className='Nav-Arrows-contained' src={require("../Assets/Images/downArrow.png")} alt="" />
                                    </div>
                                </div>
                                <div className='Text-small'>
                                    Wrapped Ether
                                </div>
                            </div>
                            <div className='main-card-inner-2'>
                                <div className='font-size-24 text-end'>
                                    1
                                </div>
                                <div className='Text-small text-end'>
                                    ~$1 891.36
                                </div>
                            </div>
                        </div>
                        <div className='Main-page-card-sec-3' >
                            <div>
                                <div className='Text-small'>
                                    You Buy
                                </div>
                                <div className='font-size-20 d-flex hover-effect icon-text-container'>

                                    <img className='icon-1-inch' src={require("../Assets/Images/dai.png")} alt="" />

                                    DAI
                                    <div className='Container-of-arrow'>

                                        <img className='Nav-Arrows-contained' src={require("../Assets/Images/downArrow.png")} alt="" />
                                    </div>
                                </div>
                                <div className='Text-small'>
                                    Dai Stablecoin
                                </div>
                            </div>
                            <div className='main-card-inner-2'>
                                <div className='font-size-24 text-end'>
                                    1888.983782
                                </div>
                                <div className='Text-small text-end'>
                                    ~$1 890.4 (-0.01%)
                                </div>
                            </div>
                        </div>
                        <div className={`Main-page-card-sec-4 d-flex justify-content-space-between ${showTab ? "hide" : ""}`}>
                            <div className='Text-small-1'>
                                <InfoOutlinedIcon style={{ color: "#617b9e", fontSize: "18px" }} />  1 ETH <span className='secondary-color'> = </span>0.3651651 DAI <span className='secondary-color'> (~$0.37) </span>
                            </div>
                            <div className='Text-small-1 d-flex'>
                                <PublicOffOutlinedIcon style={{ color: "#00888e", fontSize: "18px", marginInline: "4px" }} />
                                ~$16.37
                                <div className={` ${showTab ? "hide" : ""}`} onClick={() => setShowTab(!showTab)}>
                                    <KeyboardArrowDownOutlinedIcon style={{ padding: "0", margin: "0" }} />
                                </div>

                            </div>

                        </div>
                        <div className={`Main-page-card-sec-4-1 d-flex ${showTab ? "" : "hide"}`}>
                            <div className='space-between-section'>

                                <div><p>Swap mode</p></div>

                                <div className={` ${showTab ? "" : "hide"}`} onClick={() => setShowTab(!showTab)}>
                                    <KeyboardArrowUpOutlinedIcon style={{ padding: "0", margin: "0" }} />
                                </div>
                            </div>
                            <div className='M-p-c-s-4-1'>
                                <div className='M-p-c-s-4-1-inner1'>
                                    <PublicOffOutlinedIcon style={{ color: "#00848b" }} />
                                    <div className='white-color font-12'>
                                        <span className='seagreen-color '> Fusion </span> Auto
                                    </div>
                                    <div className='font-12 white-color'>
                                        <span className='secondary-color'> Settlement Fee </span>   ~$3.74
                                    </div>
                                </div>
                                <div className='M-p-c-s-4-1-inner2'>
                                    <PublicOffOutlinedIcon style={{ color: "#00848b" }} />
                                    <div className='white-color font-12'>
                                        <span className='seagreen-color '> Legacy </span> Custom
                                    </div>
                                    <div className='font-12 white-color'>
                                        <span className='secondary-color'> Network Fee </span>   ~$6.36
                                    </div>
                                </div>

                            </div>
                            <div className='M-p-c-s-4-2'>
                                <div className='M-p-c-s-4-2-inner'>
                                    <div className='font-13 secondary-color'>Network Fee</div>
                                    <div className='font-13 seagreen-color'>Free</div>
                                </div>
                                <div className='M-p-c-s-4-2-inner'>
                                    <div className='font-13 secondary-color'>Settlement Fee</div>
                                    <div className='font-13 secondary-color'>~$3.74777857 <span className='white-color margin-5'> 3.74326466 DAI</span>
                                    </div>
                                </div>
                                <div className='M-p-c-s-4-2-inner'>
                                    <div className='font-13 secondary-color'> Est.  ETH sell price </div>
                                    <div className='font-13 secondary-color'>~$1896.77857 <span className='white-color margin-5'> 3.66 DAI</span>
                                    </div>
                                </div>
                                <div className='M-p-c-s-4-2-inner'>
                                    <div className='font-13 secondary-color'>  Min  ETH sell price  </div>
                                    <div className='font-13 secondary-color'>~$16.77857 <span className='white-color margin-5'> 3.66321 DAI</span>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className='Main-page-card-sec-5'>
                            <div className='Navbar-btn-2 padding-y-10'>
                                <img className='Nav-wallet' src={require("../Assets/Images/wallet.png")} alt="" />
                                Connect Wallet
                            </div>
                        </div>
                    </div> */}
                    <ExchangeCard />
                    {/* <SwapSetting/> */}
                </div>
            </div>
        </div>
    )
}

export default LandingPage