import React from 'react'
import ExchangeCard from '../Components/ExchangeCard'
import bnb from '../Assets/Images/dropdown/bnb.svg'
import dai from '../Assets/Images/dropdown/dai.png'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ApexChart from '../Components/Chart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { ArrowDownwardRounded } from '@mui/icons-material';


function ClassicMode() {
    return (
        <div className='ClassicMode-page'>
            <div className='ClassicMode-page-main'>
                <div className='ClassicMode-page-first-div'>
                    <div className='chart-first-row'>
                        <div className='d-flex'>
                            <div className='Double-icon-holder'>
                                <div>
                                    <img className='w-100' src={bnb} alt="" />
                                </div>
                                <div className='second-icon-double'>

                                    <img className='w-100 shadow-of-icon' src={dai} alt="" />
                                </div>
                            </div>
                            <div className='font-20 white-color mx-4'>
                                WBND/DAI
                                <KeyboardArrowDownOutlinedIcon />
                            </div>


                        </div>
                        <div className='d-flex'>
                            <div className='duration-ribbon'>
                                <div className='duration-ribbon-inner-1' id='hide-on-mobile'>
                                    <div className='first-duration-item'>
                                        5M
                                    </div>
                                    <div>
                                        15M
                                    </div>
                                    <div>
                                        1H
                                    </div>
                                    <div>
                                        4H
                                    </div>
                                    <div>
                                        1D
                                    </div>
                                    <div>
                                        1W
                                    </div>

                                </div>
                                <div className='duration-ribbon-inner-2'>
                                    <span><AssessmentOutlinedIcon style={{ color: "white", fontSize: "24px" }} /> </span>
                                    <span><ListAltIcon style={{ color: "#6c86ad", fontSize: "24px" }} /> </span>
                                </div>
                            </div>

                        </div>

                    </div>
                    {/* <div className='durrationResponsiveRibbon'>
                        1hr
                        <KeyboardArrowDownIcon style={{ marginRight: "3px", color: "#6c86ad" }} />
                    </div> */}
                    <div class="duration-ribbon-inner-2" id='hide-on-desktop'>
                        <div class="first-duration-item-two">5M</div><div>15M</div><div>1H</div><div>4H</div><div>1D</div><div>1W</div></div>
                    <div className='chart-second-row white-color font-24'>
                        305.424908 <span className='color-green font-16'> +0.18%</span>
                    </div>
                    <div>
                        <ApexChart />
                    </div>
                </div>
                <div className='ClassicMode-page-second-div'>
                    <div>
                        <ExchangeCard />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ClassicMode