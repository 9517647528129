import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ConstructionIcon from '@mui/icons-material/Construction';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import { Link } from 'react-router-dom';
import avalanche from '../Assets/Images/dropdown/avalanche.svg'
import arbitrum from '../Assets/Images/dropdown/arbitrum.svg'
import aurora from '../Assets/Images/dropdown/aurora.svg'
import bnb from '../Assets/Images/dropdown/bnb.svg'
import fantom from '../Assets/Images/dropdown/fantom.svg'
import gnosis from '../Assets/Images/dropdown/gnosis.svg'
import optimism from '../Assets/Images/dropdown/optimism.svg'
import klaytn from '../Assets/Images/dropdown/klaytn.svg'
import polygon from '../Assets/Images/dropdown/polygon.svg'
import zksync from '../Assets/Images/dropdown/zksync.svg'
import ethereum from '../Assets/Images/dropdown/ethereum.svg'
import ResponsiveNav from './ResponsiveNav';
import eth from '../Assets/Images/eth.png'

function Header() {
    // const { coins, setCoins, selectedCoin, setSelectedCoin } = useContext(MyContext)

    // useEffect(() => {
    //     console.log("coins are ", coins)
    //     console.log("selected coin is", selectedCoin)
    // }, [coins, selectedCoin])

    return (
        <>
            <div className='Header-show-full'>
                <Navbar className='Navbar-Main' expand="lg">
                    <Container fluid>
                            <Navbar.Brand href="https://deta.io/">
                                <img className='NavLogo' src={require("../Assets/Images/logo.png")} alt="" />
                            </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav
                                className="me-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px' }}
                                navbarScroll
                            >
                                <div className='nav-items-holder'>
                                    <div className='HoverTabsHolder'>
                                        <a className='nav-titles'>Trade <img className='Nav-Arrows' src={require("../Assets/Images/downArrow.png")} alt="" /> </a>
                                        <div className='Drop-down-container'>
                                            <Link className='a-unset-all' to='/'>
                                                <a className='a-unset-all' href="">
                                                    <div className='Drop-down-items'>
                                                        <div className='Drop-down-items-icons'>
                                                            <SpaceDashboardIcon style={{ color: 'white' }} />
                                                        </div>
                                                        <div>
                                                            <p >Simple mode</p>
                                                            <p className='font-12'>
                                                                the most user-friendly way to trade
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </Link>
                                            <Link to='/classic-mode' className='a-unset-all'>
                                                <a className='a-unset-all' href="">
                                                    <div className='Drop-down-items'>
                                                        <div className='Drop-down-items-icons'>
                                                            <SpaceDashboardIcon style={{ color: 'white' }} />

                                                        </div>
                                                        <div>
                                                            <p >Classic mode</p>
                                                            <p className='font-12'>
                                                                Take advantage of all the familiar tools
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </Link>

                                        </div>
                                    </div>
                                    <div className='HoverTabsHolder'>
                                        <a className='nav-titles'>Earn <img className='Nav-Arrows' src={require("../Assets/Images/downArrow.png")} alt="" /> </a>
                                        <div className='Drop-down-container'>
                                            <a className='a-unset-all' href="">
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <AccountCircleIcon style={{ color: 'white' }} />

                                                    </div>
                                                    <div>
                                                        <p >Delegate</p>

                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' href="">
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <ConstructionIcon style={{ color: 'white' }} />

                                                    </div>
                                                    <div>
                                                        <p >Strategies</p>

                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' href="">
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <DonutSmallIcon style={{ color: 'white' }} />

                                                    </div>
                                                    <div>
                                                        <Link className='a-unset-all' to="/pool">
                                                            <p >Pools</p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </a>

                                        </div>
                                    </div>





                                    <div className='HoverTabsHolder'>
                                        <a className='nav-titles'>Bridges <img className='Nav-Arrows' src={require("../Assets/Images/downArrow.png")} alt="" /> </a>
                                        <div className='Drop-down-container'>
                                            <a className='a-unset-all' target='_blank' href="https://cbridge.celer.network/">
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img className src={bnb} alt="" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p >BNB Chain bridge</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' target='_blank' href="https://wallet.polygon.technology/polygon/bridge/">
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img class src={polygon} alt="" />
                                                        </div>                                            </div>
                                                    <div>
                                                        <p >Polygon bridge</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' target='_blank' href="https://app.optimism.io/bridge">
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img class src={optimism} alt="" />
                                                        </div>                                            </div>
                                                    <div>
                                                        <p >Optimism bridge</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' target='_blank' href="https://bridge.arbitrum.io/">
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img class src={arbitrum} alt="" />
                                                        </div>                                            </div>
                                                    <div>
                                                        <p >Arbitrum bridge</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' target='_blank' href="https://omni.gnosischain.com/bridge">
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img class src={gnosis} alt="" />
                                                        </div>                                            </div>
                                                    <div>
                                                        <p >Gnosis Chain bridge</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' target='_blank' href="https://core.app/bridge/">
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img class src={avalanche} alt="" />
                                                        </div>                                            </div>
                                                    <div>
                                                        <p >Avalanche bridge</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' target='_blank' href="https://app.multichain.org/#/router">
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img class src={fantom} alt="" />
                                                        </div>                                            </div>
                                                    <div>
                                                        <p >Fantom bridge</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' target='_blank' href="https://scope.klaytn.com/bridge">
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img class src={klaytn} alt="" />
                                                        </div>                                            </div>
                                                    <div>
                                                        <p >Klaytn bridge</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' target='_blank' href="https://rainbowbridge.app/transfer">
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img class src={aurora} alt="" />
                                                        </div>                                            </div>
                                                    <div>
                                                        <p >Aurora bridge</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' target='_blank' href="https://bridge.zksync.io/">
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img class src={zksync} alt="" />
                                                        </div>                                            </div>
                                                    <div>
                                                        <p >ZKSync Era bridge</p>
                                                    </div>
                                                </div>
                                            </a>

                                        </div>
                                    </div>




                                </div>

                            </Nav>
                            <div className="d-flex">
                                <div class="dropdown">


                                    <div className='HoverTabsHolder'>
                                        <a className='nav-titles padding-zero'>
                                            <button class="gradient-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img className='Nav-Diamond' src={eth} />
                                                Ethereum

                                            </button>
                                        </a>
                                        <div className='Drop-down-container'>
                                            <a className='a-unset-all' >
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img class src={ethereum} alt="" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p >Ethereum</p>
                                                    </div>
                                                </div>
                                            </a>

                                            <a className='a-unset-all' >
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img className src={bnb} alt="" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p >BNB Chain bridge</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' >
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img class src={polygon} alt="" />
                                                        </div>                                            </div>
                                                    <div>
                                                        <p >Polygon bridge</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' >
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img class src={optimism} alt="" />
                                                        </div>                                            </div>
                                                    <div>
                                                        <p >Optimism bridge</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className='a-unset-all' >
                                                <div className='Drop-down-items'>
                                                    <div className='Drop-down-items-icons'>
                                                        <div className='Icons-image-for-drop-down'>
                                                            <img class src={arbitrum} alt="" />
                                                        </div>                                            </div>
                                                    <div>
                                                        <p >Arbitrum bridge</p>
                                                    </div>
                                                </div>
                                            </a>

                                        </div>
                                    </div>

                                </div>
                                <div className='Navbar-btn-2'>
                                    <img className='Nav-wallet' src={require("../Assets/Images/wallet.png")} alt="" />
                                    Connect Wallet
                                </div>
                                <div className='d-flex align-items-center'>
                                    <img className='NavGear' src={require("../Assets/Images/setting.png")} alt="" />
                                </div>
                            </div>
                        </Navbar.Collapse>
                    </Container >
                </Navbar >
            </div>

            <div className='Header-show-tablet'>
                <ResponsiveNav />
            </div>

        </>
    );
}

export default Header;