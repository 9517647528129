import { useState } from 'react'
import icon1 from '../Assets/Images/pool/icon1.svg'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import jsonData from '../pool_data.json';
import Poolitem from './Poolitem';
import PoolitemResponsive from './PoolitemResponsive';
import CallMadeIcon from '@mui/icons-material/CallMade';
function Pool() {
    console.log(jsonData)
    const filteredList = jsonData.filter(
        (item) => item.token0.symbol !== "1INCH" && item.token1.symbol !== "1INCH"
    );
    const [showCountCard, setshowCountCard] = useState(false)
    const [itemPerPage, setItemPerPage] = useState(25)
    const [pageIndex, setPageIndex] = useState(0)
    const pools_data = filteredList.length
    const handleItemsPerPage = (e) => {
        setItemPerPage(Number(e))
        setshowCountCard(false)
    }
    const IncrementPageCount = () => {
        setPageIndex(pageIndex + itemPerPage)
    }
    const DecrementPageCount = () => {
        setPageIndex(pageIndex - itemPerPage)
    }


    const data_filtered = filteredList.slice(pageIndex, pageIndex + itemPerPage)
    return (
        <div className='Pool-page'>
            <div className='Pool-page-first-section'>

                <div className='Pool-first-Inner'>
                    <div className='white-color font-24'>
                        Pools
                    </div>
                    <div className='pool-switch-holder'>
                        <div className='highlight-back-color'>
                            v 1.1
                        </div>
                        <div>
                            v 1.0
                            <CallMadeIcon style={{ marginLeft: "5px", color: "#6c86ad", fontSize: "18px" }} />
                        </div>
                    </div>
                </div>
                <div className='Pool-second-Inner mt-4'>
                    <div className='white-color font-16'>
                        Filter
                    </div>
                    <div>
                        <input type="text" className='pool-filter-input' placeholder='Enter token name...' />
                    </div>
                </div>

                <div className='Pool-third-Inner'>
                    <div className='Pool-row-header hideBelow1024'>
                        <div className="Pool-row-item-1">
                            <div className='d-flex'>
                                <div className='d-flex mx-1'>

                                    <div className='font-12 secondary-color'>
                                        Pool
                                    </div>
                                </div>
                                <div className='mx-1 d-flex'>
                                    <div className='Pool-row-icon1'>
                                    </div>
                                    <div>
                                        <div className='font-12'>
                                        </div>
                                        <div className='font-12 primary-color'>
                                        </div>
                                    </div>
                                </div>
                                <div className='mx-1 d-flex'>
                                    <div className='Pool-row-icon1 '>
                                    </div>
                                    <div>
                                        <div className='font-12'>
                                        </div>
                                        <div className='font-12 primary-color'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Pool-row-item-2 margin-10">
                            <div className='font-12 secondary-color '>
                                Liquidity
                            </div>
                            <div className='font-12 secondary-color margin46px'>
                                APY

                            </div>
                        </div>
                        <div className="">
                            <div className=''>

                            </div>
                            <div>

                            </div>
                        </div>

                    </div>



                    <div className='hideBelow1024'>
                        {
                            data_filtered ?

                                data_filtered.map((item) => {
                                    if (item.token0.symbol != "1INCH" && item.token1.symbol != "1INCH") {
                                        return (

                                            <Poolitem item={item} />
                                        )
                                    }

                                })

                                :
                                <p>nothing to show</p>
                        }
                    </div>
                    <div className='showBelow1024 '>
                        {
                            data_filtered ?

                                data_filtered.map((item) => {
                                    if (item.token0.symbol != "1INCH" && item.token1.symbol != "1INCH") {
                                        return (

                                            <PoolitemResponsive item={item} />
                                        )
                                    }

                                })

                                :
                                <p>nothing to show</p>
                        }
                    </div>












                </div>


                <div className='Pool-filter-view-main white-color'>
                    <div className='d-flex align-items-center pos-relative'>
                        Items per page: {itemPerPage} <div onClick={() => setshowCountCard(!showCountCard)}> <ArrowDropDownIcon /> </div>
                        <div className={`Items-per-page-selector-card ${showCountCard ? "" : "hide"}`}>
                            <p id='5' onClick={(e) => handleItemsPerPage(e.target.id)}>
                                5
                            </p>
                            <p id='10' onClick={(e) => handleItemsPerPage(e.target.id)}>
                                10
                            </p>
                            <p id='25' onClick={(e) => handleItemsPerPage(e.target.id)}>
                                25
                            </p>
                            <p id='100' onClick={(e) => handleItemsPerPage(e.target.id)}>
                                100
                            </p>

                        </div>
                    </div>
                    <div className='d-flex align-items-center'>

                        {pageIndex + 1} - {pageIndex + itemPerPage}  of 336 <KeyboardArrowLeftIcon className={`${pageIndex - itemPerPage <= -1 ? 'disabled' : ''}`} onClick={DecrementPageCount} /> <KeyboardArrowRightIcon className={`${pools_data < (pageIndex + itemPerPage + 1) ? 'disabled' : ''}`} onClick={IncrementPageCount} />
                    </div>
                </div>










            </div>
        </div>
    )
}

export default Pool