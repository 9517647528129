import LandingPage from "./Pages/LandingPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/Assets/Style/style.css"
import GlobalProvider from "./Context/GlobalProvider";
import Pool from "./Pages/Pool";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from "./Components/Header";
import ClassicMode from "./Pages/ClassicMode";
import Footer from "./Components/Footer/Footer.jsx";

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <Header />
        <Routes>

          <Route path="/" element={<LandingPage />} />
          <Route path="/pool" element={<Pool />} />
          <Route path="/classic-mode" element={<ClassicMode />} />
          <Route element={<div>404 not found</div>} />
        </Routes>
        <Footer />
      </BrowserRouter>


    </div>
  );
}

export default App;
