// import React, { useState } from 'react';
// import ReactApexChart from 'react-apexcharts';

// const ApexChart = () => {
//     const [series] = useState([
//         {
//             data: [
//                 { x: new Date(1538778600000), y: [6629.81, 6650.5, 6623.04, 6633.33] },
//                 { x: new Date(1538780400000), y: [6632.01, 6643.59, 6620, 6630.11] },
//                 { x: new Date(1538782200000), y: [6630.71, 6648.95, 6623.34, 6635.65] },
//                 { x: new Date(1538784000000), y: [6635.65, 6651, 6629.67, 6638.24] },

//             ],
//         },
//     ]);

//     const [options] = useState({
//         chart: {
//             type: 'candlestick',
//             height: 350,
//         },

//         xaxis: {
//             type: 'datetime',
//         },
//         yaxis: {
//             tooltip: {
//                 enabled: true,
//             },
//         },
//     });

//     return (
//         <div id="chart">
//             <ReactApexChart options={options} series={series} type="candlestick" height={350} />
//         </div>
//     );
// };

// export default ApexChart;

import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = () => {
    const [series] = useState([
        {
            data: [
                { x: new Date(2023, 3, 3), y: [6629.81, 6650.5, 6623.04, 6633.33, 6635.65, 6651, 6629.67, 6638.24] },
                { x: new Date(2023, 3, 13), y: [6632.01, 6643.59, 6620, 6630.11, 6629.81, 6650.5, 6623.04, 6633.33] },
                { x: new Date(2023, 3, 23), y: [6630.71, 6648.95, 6623.34, 6635.65, 6635.65, 6651, 6629.67, 6638.24] },
                { x: new Date(2023, 5, 2), y: [6635.65, 6651, 6629.67, 6638.24, 6630.71, 6648.95, 6623.34, 6635.65] },
                { x: new Date(2023, 5, 12), y: [6629.81, 6650.5, 6623.04, 6633.33, 6635.65, 6651, 6629.67, 6638.24] },
                { x: new Date(2023, 5, 22), y: [6632.01, 6643.59, 6620, 6630.11, 6629.81, 6650.5, 6623.04, 6633.33] },
                { x: new Date(2023, 6, 1), y: [6630.71, 6648.95, 6623.34, 6635.65, 6635.65, 6651, 6629.67, 6638.24] },
                { x: new Date(2023, 6, 11), y: [6629.81, 6650.5, 6623.04, 6633.33, 6635.65, 6651, 6629.67, 6638.24] },
                { x: new Date(2023, 6, 21), y: [6632.01, 6643.59, 6620, 6630.11, 6629.81, 6650.5, 6623.04, 6633.33] },
                { x: new Date(2023, 6, 31), y: [6630.71, 6648.95, 6623.34, 6635.65, 6635.65, 6651, 6629.67, 6638.24] },
                { x: new Date(2023, 7, 8), y: [6630.71, 6648.95, 6623.34, 6635.65, 6635.65, 6651, 6629.67, 6638.24] },
                { x: new Date(2023, 7, 18), y: [6632.01, 6643.59, 6620, 6630.11, 6629.81, 6650.5, 6623.04, 6633.33] },
                { x: new Date(2023, 7, 28), y: [6630.71, 6648.95, 6623.34, 6635.65, 6635.65, 6651, 6629.67, 6638.24] },
                { x: new Date(2023, 8, 6), y: [6632.01, 6643.59, 6620, 6630.11, 6629.81, 6650.5, 6623.04, 6633.33] },
                { x: new Date(2023, 8, 16), y: [6630.71, 6648.95, 6623.34, 6635.65, 6635.65, 6651, 6629.67, 6638.24] },
                { x: new Date(2023, 8, 26), y: [6632.01, 6643.59, 6620, 6630.11, 6629.81, 6650.5, 6623.04, 6633.33] },
                { x: new Date(2023, 9, 6), y: [6630.71, 6648.95, 6623.34, 6635.65, 6635.65, 6651, 6629.67, 6638.24] },
            ],
        },
    ]);

    const [options] = useState({
        chart: {
            type: 'candlestick',
            height: 350,
            toolbar: {
                show: false, // Hide the toolbar with zoom and download options
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: '#ffffff', // Set the x-axis labels text color to white
                },
                formatter: function (value, timestamp) {
                    const options = { day: 'numeric', month: 'short' };
                    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp));
                    return formattedDate;
                },
            },
            tickAmount: 10, // Display ticks at every 10th date
            min: new Date(2023, 3, 3).getTime(), // Set the minimum date value
            max: new Date(2023, 10, 2).getTime(), // Set the maximum date value
        },
        yaxis: {
            tooltip: {
                enabled: true,
            },
            labels: {
                style: {
                    colors: '#ffffff', // Set the y-axis labels text color to white
                },
            },
        },
        colors: ['#ffffff'], // Set the color of the chart elements to white
        grid: {
            row: {
                colors: ['transparent'], // Set the row background color to transparent
            },
        },
        annotations: {
            yaxis: [
                {
                    y: 6640, // Threshold value
                    borderColor: '#00ff00', // Green color
                    borderWidth: 1,
                    label: {
                        borderColor: '#00ff00', // Green color
                        style: {
                            color: '#00ff00', // Green color
                            background: '#ffffff', // White color
                        }
                    },
                },
            ],
            strokeDashArray: 5, // Set the length of dashes in the line
        },
    });

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="candlestick" height={350} />
        </div>
    );
};

export default ApexChart;
